<template>
  <v-btn :loading="loading" class="d-flex align-center font-weight-bold rounded-pill ml-2 justify-center pa-0 fill-width fill-height green lighten-4" light outlined @click.prevent="IMPORT_DATA()">
    <v-icon small class="mr-1">
      mdi-file-import
    </v-icon>
    Import
  </v-btn>
</template>

<script>
export default {
  data: () => ({
    dialogConfirmUpload: false,
    isError: [],
    dataLoaded: false,
    file: null,
    sheets: {},
    loading: false,
    sheetSelected: null
  }),
  methods: {
    parsed (d) {
      this.isError = []
      /* eslint-disable */
      this.dataLoaded = false
      if (d.Strings.length) {
        if (d.SheetNames.length) {
          this.sheets = {}
          Object.keys(d.Sheets).forEach((sheetName) => {
            this.sheets[sheetName] = []
            const dd = d.Sheets[sheetName]
            console.log(dd)
          })
          setTimeout(() => {
            this.dataLoaded = true
          }, 1500)
          this.sheetSelected = null
          this.dialogConfirmUpload = true
        } else {
          this.$store.dispatch('TOAST', { show: true, message: 'Tidak ada sheet/halaman pada file!' })
        }
      }
    },
    IMPORT_DATA () {
      this.sheets = []
      this.sheetSelected = null
      let u = document.getElementById('file-uploader')
      if (u) {
        u.remove()
      }
      setTimeout(() => {
        u = document.createElement('input')
        u.setAttribute('type', 'file')
        u.setAttribute('id', 'file-uploader')
        u.setAttribute('class', 'file-uploader')
        u.setAttribute('accept', '.xls,.xlsx')
        u.addEventListener('change', (e) => {
          const f = e.target.files ? e.target.files[0] : null
          this.UPLOAD(f)
        })
        document.body.appendChild(u)
        u.click()
      }, 100)
    },
    async UPLOAD (file) {
      const d = new FormData()
      if (file) {
        d.append('file', file)
      } else {
        this.$store.dispatch('TOAST', { show: true, message: 'File undefined!' })
        return false
      }
      this.loading = true
      await this.$store.dispatch('logistic/IMPORT_PRICING', d)
      this.loading = false
    }
  }
}
</script>
